.img-fluid {
  cursor: pointer;
}

/* Centered text */
.centered {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}